<template>
  <figure class="media">
    <UiImage v-if="finalImage && !finalVideo" v-bind="finalImage" />
    <UiVideo v-if="finalVideo" v-bind="finalVideo" :poster="finalImage" />
    <figcaption v-if="legend" class="_legend">{{ legend }}</figcaption>
  </figure>
</template>
<script setup lang="ts">
import './media.css'
import UiImage from '~/components/ui/image/image.vue'
import UiVideo from '~/components/ui/video/video.vue'

import type { TImage } from '~/components/ui/image/image.type'
import type { TVideo } from '~/components/ui/video/video.type'
import { type TMedia } from './media.type'

const props = withDefaults(defineProps<TMedia>(), {})

const finalVideo = props.video as TVideo
if (finalVideo) {
  finalVideo.advanced = props.advanced
}

const finalImage = props.image as TImage
if (finalImage) {
  finalImage.advanced = props.advanced
}
</script>
