<template>
  <i class="icon" role="img" :aria-label="label" v-html="svgContent"></i>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue'
import type { TIcon } from './icon.type'

const props = withDefaults(defineProps<TIcon>(), {
  name: 'cross',
  label: 'Close',
})

const svgContent = ref('')

const loadSvg = async () => {
  try {
    const svgModule = await import(
      `~/components/ui/icon/svg/${props.name}.svg?raw`
    )
    svgContent.value = svgModule.default
  } catch (error) {
    console.error('Missing SVG icon:', error)
  }
}

watchEffect(() => {
  loadSvg()
})
</script>
