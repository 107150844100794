<template>
    <div :class="`video ${isPlaying ? '-playing' : isPaused ? '-paused' : ''}`">
        <video class="_video" ref="$video" :src="src ?? url" :muted="muted" :autoplay="autoplay" :loop="loop"
            :controls="controls" :preload="preload"></video>
        <div class="_overlay" v-on:click="toggleVideoState">
            <UiImage class="_poster" v-bind="poster" />
            <div class="_play">
                <!--        <svg-->
                <!--          viewBox="0 0 24 24"-->
                <!--          fill="currentColor"-->
                <!--          xmlns="http://www.w3.org/2000/svg"-->
                <!--          id="Play-Mini-Fill&#45;&#45;Streamline-Remix-Fill"-->
                <!--        >-->
                <!--          <path-->
                <!--            d="M7.75194 5.43872L18.2596 11.5682C18.4981 11.7073 18.5787 12.0135 18.4396 12.252C18.3961 12.3265 18.3341 12.3885 18.2596 12.432L7.75194 18.5615C7.51341 18.7006 7.20725 18.62 7.06811 18.3815C7.0235 18.305 7 18.2181 7 18.1296V5.87061C7 5.59446 7.22386 5.37061 7.5 5.37061C7.58853 5.37061 7.67547 5.39411 7.75194 5.43872Z"-->
                <!--            stroke-width="1"-->
                <!--          ></path>-->
                <!--        </svg>-->
                <UiIcon name="play" label="Play" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import UiIcon from '~/components/ui/icon/icon.vue'
import UiImage from '~/components/ui/image/image.vue'

import './video.css'
import { type TVideo } from './video.type.ts'

// import { __isVideoPlaying } from '@lotsof/sugar/is';

const props = withDefaults(defineProps<TVideo>(), {
    muted: false,
    autoplay: false,
    loop: false,
    playsinline: false,
    controls: true,
    preload: 'none',
})

const isPlaying = ref(false)
const isPaused = ref(false)

const $video = useTemplateRef('$video')

onMounted(() => {
    // if (__isVideoPlaying($video.value)) {
    //     console.log('PLAY');
    // }

    $video?.value?.addEventListener('play', () => {
        isPaused.value = false
        isPlaying.value = true
    })
    $video?.value?.addEventListener('pause', () => {
        isPaused.value = true
        isPlaying.value = false
    })
})

const toggleVideoState = () => {
    if (isPlaying.value) {
        $video?.value?.pause()
    } else {
        $video?.value?.play()
    }
}
</script>
